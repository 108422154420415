import React from 'react'
import { graphql } from 'gatsby'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/contactus.scss'
import { Navbar } from '../components/navbar'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const Contactus = ({ data }) => {
	const { t } = useTranslation()
	return (
		<Layout>
			<SEO
				title={t('title')}
				keywords={[t('keywords.free_crm_for_real_estate')]}
				description="Are looking to improve your sales process, then trying out CRM software is a must. Luckily, many CRM solutions offer free demos,"
			/>
			<div className="contactus-page">
				<Navbar />
				<div className="page-content">
					<div className="contactus-section">
						<MDXRenderer>{data.Contact.nodes[0].body}</MDXRenderer>
					</div>
				</div>
				<div className="footer-section">
					<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	)
}
export const pageQuery = graphql`
	query ContactusQuery($language: String!) {
		locales: allLocale(filter: { ns: { in: ["contactUs"] }, language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
		Contact: allMdx(filter: { frontmatter: { title: { eq: "Contact" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Contactus
